<template>
    <div class="home">
        <el-container>
            <el-aside width="100px">
                <left-side-bar />
            </el-aside>
            <el-main>
                <div class="w-100">
                    <Header />
                </div>
                <div>
                    <el-row :gutter="24">
                        <el-col :span="12"
                            ><div class="grid-content bg-purple"></div>
                            <chart-by-status></chart-by-status>
                        </el-col>
                        <el-col :span="12"
                            ><div class="grid-content bg-purple"></div>
                            <chart-by-order-status></chart-by-order-status>
                        </el-col>
                    </el-row>
                    <el-row :gutter="24">
                        <el-col :span="12"
                            ><div class="grid-content bg-purple"></div>
                            <chart-by-orders-months></chart-by-orders-months>
                        </el-col>
                        <el-col :span="12"
                            ><div class="grid-content bg-purple"></div>
                            <chart-user-role></chart-user-role>
                        </el-col>
                    </el-row>
                </div>
            </el-main>
        </el-container>
    </div>
</template>

<script>
import { defineComponent } from "vue";
import leftSideBar from "@/components/dashboard/menu/leftSideBar";
import Header from "@/components/dashboard/include/Header";
import ChartByStatus from "../../components/dashboard/chart/ChartOrderByStatus.vue";
import ChartByOrderStatus from "../../components/dashboard/chart/ChartOrderByOrderStatus.vue";
import ChartByOrdersMonths from "../../components/dashboard/chart/ChartOrderByMonths.vue";
import ChartUserRole from "../../components/dashboard/chart/ChartAplicationUserByRole.vue";

export default defineComponent({
    name: "Dashboard",
    components: {
        leftSideBar,
        Header,
        ChartByStatus,
        ChartByOrderStatus,
        ChartByOrdersMonths,
        ChartUserRole,
    },
});
</script>
<style scoped>
.color-icon {
    color: white;
}
</style>
