<template>
    <div style="width: 100%">
        <canvas id="chart-by-process-status"></canvas>
    </div>
</template>

<script>
import Chart from "chart.js";
import _service from "@/services/orderService";

export default {
    name: "PlanetChart",
    data() {
        return {
            ChartData: null,
            labelChart: [],
            valueChart: [],
        };
    },
    async mounted() {
        const data = await _service.getResumenByOrderStatus();
        var datos = data.data;
        var object = function (key, text) {
            this.key = key;
            this.text = text;
        };

        var objArray = [];
        objArray[0] = new object("Pendiente por Recoger");
        objArray[1] = new object("Pendiente de Verificación");
        objArray[2] = new object("En Verificación");
        objArray[3] = new object("Verificado");
        objArray[4] = new object("Pendiente de Empaque");
        objArray[5] = new object("Empacado");
        objArray[6] = new object("Pendiente de Entrega");
        objArray[7] = new object("Entregado");
        objArray[8] = new object("Fallo de Verificación");
        objArray[9] = new object("En Devolución");
        objArray[10] = new object("Devuelto");

        var keys = objArray.map(function (o) {
            return o.key;
        });

        this.labelChart = keys; // ["Activo", "En proceso", "Cancelada", "Completada"];
        this.valueChart = [
            datos["ordersPendingCollet"],
            datos["ordersPendingVerification"],
            datos["ordersInVerification"],
            datos["ordersVerified"],
            datos["ordersPackingPending"],
            datos["ordersPacked"],
            datos["ordersPendingDelivery"],
            datos["ordersDelivered"],
            datos["ordersVerificationFailure"],
            datos["ordersInReturn"],
            datos["ordersReturned"],
        ];
        const ChartData = {
            type: "bar",
            data: {
                labels: this.labelChart,
                datasets: [
                    {
                        label: "Resumen de Ordenes por Estatus del Proceso de la Orden",
                        data: this.valueChart,
                        backgroundColor: "#3F69A6",
                        borderColor: "#3F69A6",
                        borderWidth: 3,
                    },
                ],
            },
            options: {
                responsive: true,
                lineTension: 1,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                padding: 25,
                            },
                        },
                    ],
                },
            },
        };

        this.ChartData = ChartData;

        const ctx = document.getElementById("chart-by-process-status");
        new Chart(ctx, this.ChartData);
    },
};
</script>
