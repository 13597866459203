import { api } from "../core/api";

export default {
    getOrders(params) {
        return api.get("/Order/GetAll", { params: params });
    },
    getOrder(param) {
        return api.get("/Order/Get", { params: param });
    },
    getOrderByNeighborhood(param) {
        return api.get("/Order/GetByNeighborhood", { params: param });
    },
    packOrder(id) {
        return api.put("/Order/Packed?orderId=" + id);
    },
    verificationFail(id) {
        return api.put("/Order/VerificationFail?orderId=" + id);
    },
    verificationSuccess(id) {
        return api.put("/Order/PendingPacking?orderId=" + id);
    },
    getResumenByStatus() {
        return api.get("/Order/GetResumenByStatus");
    },
    getResumenByOrderStatus() {
        return api.get("/Order/GetResumenByOrderStatus");
    },
    getResumenByMonths() {
        return api.get("/Order/GetOrderResumenByMonths");
    },
};
