<template>
    <div style="width: 100%">
        <canvas id="chart-by-months"></canvas>
    </div>
</template>

<script>
import Chart from "chart.js";
import _service from "@/services/orderService";

export default {
    name: "PlanetChart",
    data() {
        return {
            ChartData: null,
            labelChart: [],
            valueChart: [],
        };
    },
    async mounted() {
        const data = await _service.getResumenByMonths();
        var datos = data.data;
        var object = function (key, text) {
            this.key = key;
            this.text = text;
        };

        var objArray = [];
        objArray[0] = new object("Enero");
        objArray[1] = new object("Febrero");
        objArray[2] = new object("Marzo");
        objArray[3] = new object("Abril");
        objArray[4] = new object("Mayo");
        objArray[5] = new object("Junio");
        objArray[6] = new object("Julio");
        objArray[7] = new object("Agosto");
        objArray[8] = new object("Septiembre");
        objArray[9] = new object("Octubre");
        objArray[10] = new object("Noviembre");
        objArray[11] = new object("Diciembre");

        var keys = objArray.map(function (o) {
            return o.key;
        });

        this.labelChart = keys; // ["Activo", "En proceso", "Cancelada", "Completada"];
        this.valueChart = [
            datos["ordersJanuary"],
            datos["ordersFebruary"],
            datos["ordersMarch"],
            datos["ordersMay"],
            datos["ordersJune"],
            datos["ordersJuly"],
            datos["ordersAugust"],
            datos["ordersSeptember"],
            datos["ordersOctober"],
            datos["ordersNovember"],
            datos["ordersDecember"],
        ];
        const ChartData = {
            type: "line",
            data: {
                labels: this.labelChart,
                datasets: [
                    {
                        label: "Resumen de Ordenes por Mes (Año Actual)",
                        data: this.valueChart,
                        borderColor: "#3F69A6",
                        borderWidth: 3,
                    },
                ],
            },
            options: {
                responsive: true,
                lineTension: 1,
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                padding: 25,
                            },
                        },
                    ],
                },
            },
        };

        this.ChartData = ChartData;

        const ctx = document.getElementById("chart-by-months");
        new Chart(ctx, this.ChartData);
    },
};
</script>
